import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./../enums";
import VueSweetalert2 from "vue-sweetalert2";
import VueJalali from "vue-jalali-moment";
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-search-select/dist/VueSearchSelect.css";
import ToggleButton from "vue-js-toggle-button";
import VuePersianDatetimePicker from "vue-persian-datetime-picker"; //persian calender

Vue.component("date-picker", VuePersianDatetimePicker);
Vue.use(VueJalali);
Vue.use(VueSweetalert2);
Vue.use(ToggleButton);
import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
